import {
  LocalDrink as LocalDrinkIcon,
  WaterDamage as WaterDamageIcon,
  Pending as PendingIcon,
  Grass as GrassIcon
} from "@mui/icons-material";

const CustomPin: React.FC<any> = ({
  onClick,
  category,
  source,
  status,
  isNewIssue,
  isStatusChangeIssue,
  isUpdateIssue,
  isReopenIssue,
}) => {
  let backgroundColor = "#ff5252";
  let IconComponent = PendingIcon;

  if (category === "น้ำดื่มน้ำบริโภค") {
    IconComponent = LocalDrinkIcon;
  } else if (category === "น้ำใช้อุปโภค") {
    IconComponent = WaterDamageIcon;
  } else if (category === "น้ำเพื่อการเกษตร") {
    IconComponent = GrassIcon;
  }

  if (status === "wait") {
    backgroundColor = "red";
  } else if (status === "forward") {
    backgroundColor = "#4285f4";
  } else if (status === "process") {
    backgroundColor = "#c78424";
  } else if (status === "close") {
    backgroundColor = "#549c35";
  }

  return (
    <div
      className={`pin-container ${isNewIssue || isStatusChangeIssue || isUpdateIssue || isReopenIssue ? "new-issue-animation" : ""}`}
      onClick={onClick}
      style={{
        cursor: "pointer",
        position: "relative",
        display: "inline-block",
      }}
    >
      <div
        className={source === "line"? "pin": "triangle"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...source === "line"
          ? { backgroundColor: backgroundColor }
          : {
              borderTop: `25px solid ${backgroundColor}`
            }
        }}
      >
        <IconComponent style={{ color: "white", fontSize: "12px" , ...source === "line"
          ?{marginBottom: 0}
          :{marginBottom: 30} }} />
      </div>
      <div
        className="pin-point"
        style={{
          content: "",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          width: "2px",
          height: "10px",
          backgroundColor: backgroundColor,
          ...source === "line"
          ?{bottom: "-10px"}
          :{bottom: "-10px"}
        }}
      ></div>
    </div>
  );
};

export default CustomPin;
