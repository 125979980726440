import axios from "axios";
import env from "../env";

const axiosDirectus = axios.create({
    baseURL: env.GEO_DIRECTUS_URL,
    headers: {
        Authorization: `Bearer ${env.GEO_DIRECTUS_TOKEN}`
    }
})

export const getGeoJson = async (leyer: string) => {
    const { data } = await axiosDirectus.get(`/${leyer}?sort=-date_created&limit=1`)
    return data?.data?.[0]?.geojson
}

export const getItem = async (item: string):Promise<any[]> => {
    const { data } = await axiosDirectus.get(`/${item}`)
    return data?.data || []
}

export const getProvince = async ()=>{
    return await getItem('province')
}

export const getAmphoe = async (provinceCode:string)=>{
    return await getItem(`amphoe?filter[province_code][_eq]=${provinceCode}`)
}

export const getTambon = async (amphoeCode:string)=>{
    return await getItem(`tambon?filter[amphoe_code][_eq]=${amphoeCode}`)
}
