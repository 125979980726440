import React, {useEffect, useState} from "react";
import {
    Box,
    Card,
    Autocomplete,
    TextField
  } from "@mui/material";
import { getProvince } from "../../../../api/directus";
import AsyncStorage from '@react-native-async-storage/async-storage';

  const FilterProvince = ({ selectedProvince,setSelectedProvince }:any) => {

    const [province, setProvince] = useState<any>([]);

    useEffect(() => {
      initProvince()
    }, [])

    const initProvince = async () => {
      setProvince(await getProvince())
    }

    useEffect(() => {
      const loadStatus = async () => {
        const storedStatus = await AsyncStorage.getItem('selectedProvince');
        if (storedStatus !== null) {
          setSelectedProvince(JSON.parse(storedStatus)); 
        }
      };
  
      loadStatus();
    }, [setSelectedProvince]);

    const updateValue:any = async (value:any)=> {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('selectedProvince', jsonValue)
      setSelectedProvince(value)
    }

    return (
      <Card
      sx={{
        width: 330,
        position: "absolute",
        left: 0,
        margin: 1,
        marginTop: `${1}vh`,
        zIndex: 1,
        backgroundColor:"transparent",
        boxShadow:"none"
      }}
    >

    <Autocomplete
      value={selectedProvince || null}
      id="filter-province"
      onChange={(event, newValue) => updateValue(newValue)}
      sx={{ marginTop: 1, marginBottom: 1, backgroundColor: "white", borderRadius: 2 }}
      options={province} 
      autoHighlight
      getOptionLabel={(option) => option.province_name}
      renderOption={(props, option) => (
        <Box component="li" {...props} style={{ fontFamily: 'Mitr' }}>
          {option.province_name}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="ค้นหาจังหวัด"
          InputLabelProps={{
            style: { fontFamily: 'Mitr' },
          }}
          InputProps={{
            ...params.InputProps,
            style: { fontFamily: 'Mitr' },
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'province',
            style: { fontFamily: 'Mitr' }
          }}
        />
      )}
    />
    </Card>
    );
  };
  
  export default FilterProvince;
  