import { fetchData, postData } from "../../api/api";

import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { GenerateAppKey } from "../../utils/GenerateAppKey";
import env from "../../env";
import { Button, Card, CardContent, Chip, CircularProgress, Typography, Modal, Box, TextField, MenuItem } from "@mui/material";
import GetIcon from "../../utils/GetIcon";
import { format } from 'date-fns';
import { th } from 'date-fns/locale';

function App() {
    const { param } = useParams();
    const [data, setData] = useState<any>()
    const [loading, setLoading] = useState<any>(false)
    const [open, setOpen] = useState(false);
    const [description, setDescription] = useState<any>("")
    const [category, setCategory] = useState<any>("")

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [imageData, setImageData] = useState<{ [key: string]: string }>({});

    const getIssueData = () => {
        fetchData(`/?columns=issue_id,user_id,file_url,latitude,longitude,category,status,created_at,updated_at,user_sources,is_active,reopen,description,other_detail&issue_id=${param}`)
            .then((apiData) => {
                setData(apiData[0])
                setDescription(apiData[0].description)
                setCategory(apiData[0].category)
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    const formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return format(date, 'dd MMMM yyyy HH:mm:ss', { locale: th });
    };

    function MapImage(lat: any, lng: any) {
        const mapUrl = `https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+ff0000(${lng},${lat})/${lng},${lat},12/400x400?access_token=${env.MAPBOX_TOKEN}`;
        return <img src={mapUrl} style={{ width: "300px" }} alt="Map" />
    }

    function reopenIssue() {
        const isConfirmed = window.confirm('คุณแน่ใจหรือไม่ว่าต้องการแจ้งปัญหานี้ซ้ำ?');
    
        if (isConfirmed) {
        postData(`/reopen`, { issue_id: param })
            .then(() => getIssueData())
            .catch((error) => console.error("Error fetching data:", error));
        }
    }

    function deactivateIssue() {
        const isConfirmed = window.confirm('คุณแน่ใจหรือไม่ว่าต้องการลบ?');
    
        if (isConfirmed) {
            postData(`/deactivate`, { issue_id: param })
            .then(() => getIssueData())
            .catch((error) => console.error("Error fetching data:", error));
        } else {
        console.log('Issue deletion canceled.');
        }
        
    }

    function updateIssue() {
        postData(`/update-issue`, { issue_id: param, description: description, category: category })
            .then(() => {
                handleClose()
                getIssueData()
            })
            .catch((error) => console.error("Error fetching data:", error));
    }

    function GetStatus(status: string) {
        switch (status) {
            case "wait":
                return "รอดำเนินการ";
            case "forward":
                return "ส่งต่อ";
            case "process":
                return "อยู่ระหว่างดำเนินการ";
            case "close":
                return "เสร็จสิ้น";
            default:
                return "";
        }
    }

    async function fetchImageAsDataURL(imagePath: string): Promise<string> {
        const { timestamp, appKey } = GenerateAppKey();
        try {
            const response = await fetch(`${env.API_URL}/image/${imagePath}`, {
                headers: {
                    'Timestamp': timestamp.toString(),
                    'App-Key': appKey,
                },
            });

            if (!response.ok) {
                throw new Error('Image not found');
            }

            const blob = await response.blob();
            return URL.createObjectURL(blob);
        } catch (error) {
            console.error('Failed to fetch image:', error);
            return '/images/nophoto.jpg';
        }
    }

    useEffect(() => {
        if (data) {
            setLoading(true);
            fetchImageAsDataURL(data.file_url).then((dataUrl) => {
                setImageData((prev) => ({ ...prev, [data.file_url]: dataUrl }));
                setLoading(false);
            });
        }
    }, [data]);

    useEffect(() => {
        getIssueData()
    }, []);

    const categories = [
        {
            value: 'น้ำใช้อุปโภค',
            label: 'น้ำใช้อุปโภค',
        },
        {
            value: 'น้ำดื่มน้ำบริโภค',
            label: 'น้ำดื่มน้ำบริโภค',
        },
        {
            value: 'น้ำเพื่อการเกษตร',
            label: 'น้ำเพื่อการเกษตร',
        }
    ];

    return (
        <div style={{ width: "100%", overflowY: "auto", height: "100vh" }}>

            {!data ?
                data?.is_active 
                    ? 
                    <div style={{ width: "100%", height: "100%", textAlign: "center", marginTop: "50%" }}>
                        <CircularProgress style={{ justifySelf: "center" }} />
                    </div>
                    :
                    <div style={{ width: "100%", height: "100%", textAlign: "center", marginTop: "50%" }}>
                        การแจ้งปัญหานี้ได้ถูกลบไปแล้วกรุณาปิดหน้าต่าง
                    </div>    
                :
                <div>
                    <div>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={style}>
                                <TextField sx={{ width: "100%", marginBottom: 2 }} id="description" label="คำอธิบาย" variant="outlined" defaultValue={data.description} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    setDescription(event.target.value);
                                }} />
                                <TextField
                                    id="categories"
                                    select
                                    sx={{ width: "100%", marginBottom: 2 }}
                                    label="Select"
                                    defaultValue="น้ำใช้อุปโภค"
                                    helperText="เลือกประเภทปัญหาที่ขอความช่วยเหลือ"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setCategory(event.target.value)
                                    }}
                                >
                                    {categories.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <Button onClick={updateIssue} sx={{ fontFamily: "Mitr", width: "100%" }} variant="contained" >
                                    แก้ไข
                                </Button>
                            </Box>
                        </Modal>
                    </div>
                    <div style={{ textAlign: "center" }}>

                        {loading ?
                            <CircularProgress />
                            :
                            data?.file_url?.endsWith('.mp4') ? (
                                <video style={{ width: 300, borderRadius: 10 }} controls>
                                    <source src={imageData[data.file_url]} type="video/mp4" />
                                    {/* <source src={`https://aws-api.isoc.go.th/image-line/${data?.file_url}`} type="video/mp4" /> */}
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img
                                    style={{ width: 300, borderRadius: 10 }}
                                    src={imageData[data.file_url]}
                                    // src={`https://aws-api.isoc.go.th/image-line/${data?.file_url}`}
                                    alt="Issue content"
                                />
                            )
                        }
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {MapImage(data.latitude, data.longitude)}
                    </div>
                    <div style={{ padding: 10 }}>
                        <Card sx={{ width: "100%", borderRadius: 4 }}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    {data.description}
                                </Typography>
                                <Typography variant="body2" color="darkslategrey">
                                    {data.category}
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    รายละเอียดสถานที่: {data.other_detail}
                                </Typography>

                                <Chip
                                    sx={{
                                        float: "inline-end",
                                        backgroundColor:
                                            data.status === "wait"
                                                ? "red"
                                                : data.status === "forward"
                                                    ? "#4285f4"
                                                    : data.status === "process"
                                                        ? "#c78424"
                                                        : data.status === "close"
                                                            ? "#549c35"
                                                            : "defaultColor",
                                        color: "white",
                                        fontSize: 10,
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                    icon={GetIcon(GetStatus(data.status))}
                                    label={GetStatus(data.status)}
                                />
                                <Chip
                                    sx={{
                                        float: "inline-end",
                                        color: "grey",
                                        fontSize: 10,
                                        marginTop: 2,
                                        marginBottom: 2,
                                    }}
                                    label={`Reopen: ${data.reopen}`}
                                />
                                {formatDate(data.created_at)}
                            </CardContent>
                        </Card>
                        <div style={{ textAlign: 'center', marginTop: 5, }}>
                            <Button disabled={data.status !== "wait"} onClick={handleOpen} sx={{ fontFamily: "Mitr", width: "49%", marginRight: "2%" }} variant="contained" >
                                แก้ไข
                            </Button>
                            <Button onClick={deactivateIssue} sx={{ fontFamily: "Mitr", width: "49%" }} variant="contained" color="error">
                                ลบ
                            </Button>
                        </div>
                        <div style={{ textAlign: 'center', marginTop: 5 }}>
                            {data.status === "close" &&
                                <Button onClick={() => { reopenIssue() }} sx={{ fontFamily: "Mitr", width: "100%" }} variant="outlined" color="error">
                                    แจ้งปัญหานี้อีกครั้ง
                                </Button>
                            }
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default App;

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "80%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};