import {
  Box,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import AntSwitch from "../AntSwitch";
import { useMap } from "react-map-gl";
import { Map } from "mapbox-gl";
import { RefObject } from "react";
import { useLayerContext } from "../../../../hooks/useMapBox";
import { ToggleData } from "../../../../components/layer/layers";

interface ToggleBoxProps {
  layer: ToggleData;
  marginTop: number;
}

interface MapSidebarProps {
  map: RefObject<Map>;
}
const MapSidebar = ({ map }: MapSidebarProps) => {
  const { layers, toggleLayer } = useLayerContext();

  const ToggleBox: React.FC<ToggleBoxProps> = ({ layer, marginTop }) => (
    <Card
      sx={{
        width: "300px",
        position: "absolute",
        right: 0,
        margin: 1,
        marginTop: `${marginTop}vh`,
        zIndex: 1,
        borderRadius: 3,
        fontWeight: 300,
      }}
    >
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        component="span"
        alignItems={"center"}
        sx={{ p: 1 }}
      >
        <>{layer.label}</>
        <FormControlLabel
          onChange={(e) => toggleLayer(layer?.layerConfig?.id)}
          control={
            <AntSwitch color="primary" checked={layer?.layerConfig?.visible} />
          }
          label={""}
          labelPlacement="start"
          sx={{ justifyContent: "space-between", p: 1 }}
        />
      </Stack>
    </Card>
  );

  return (
    <div style={{marginTop:55}}>
      {layers.map((layer, index) => (
        <ToggleBox key={index} layer={layer} marginTop={8.5 + index * 6.5} />
      ))}
    </div>
  );
};

export default MapSidebar;
