const env = {
  GEOSERVER_URL: "https://drought-cms.maholan.net/geoserver",
  GEO_DIRECTUS_URL: "https://drought-cms.maholan.net/items",
  GEO_DIRECTUS_TOKEN: "fMxLn9x-Rbjz1RH4bsWMwKCfQIkM6cv1",
  MAPBOX_TOKEN:
    "pk.eyJ1Ijoicm9nc2dtciIsImEiOiJjanhwbm1wczIwM2VmM2hrMjUzN293ZjB4In0.K51-QcYhigwnd7sD2tNUbQ",
  MAPBOX_STYLE:"mapbox://styles/rogsgmr/clu11staj00n701qsceyx0nnc",
  LINE_URL: "https://drought-line.maholan.net",
  API_URL: "https://drought-api.maholan.net",
  // LINE_URL: "https://aws-api-line.isoc.go.th",
  // API_URL: "https://aws-api.isoc.go.th",
  SALT:"4lxaBxrzPpFqYTH1DisV660VVZV1Ot0FUmxBNdLN2A7u3KtQD7FmXfuXDIqM5vtGp1eOenmTefMqpurwstOcxIl0esaMhrCn7YAbTVrSytj7fWGr1QGYFz2kuE3U1i0T"
};

export default env;
