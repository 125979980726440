import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage"; 
import LinePage from "./pages/LinePage/LinePage";
import PDPAPage from "./pages/PDPAPage/PDPAPage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/line/:param" element={<LinePage />} />
      <Route path="/pdpa" element={<PDPAPage />} />
    </Routes>
  );
}

export default App;
