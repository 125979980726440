import React, { useEffect, useState, useCallback } from "react";

function App() {
    const [fileUrl, setFileUrl] = useState('');

    useEffect(() => {
        setFileUrl('files/PDPA111.pdf');
    }, []);

    return (
        <embed src="files/PDPA111.pdf" type="application/pdf" width="100%" height="100%" />
    );
}

export default App;