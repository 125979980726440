import CryptoJS from 'crypto-js';
import env from "../env";

const SALT = env.SALT;

const generate = (timestamp: number) => {
  const hmac = CryptoJS.HmacSHA256(timestamp.toString(), SALT);
  return hmac.toString(CryptoJS.enc.Hex);
}

export const GenerateAppKey = () => {
  const timestamp = Date.now();
  const appKey = generate(timestamp);

  return { timestamp, appKey };
}
