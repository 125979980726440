import React,{useEffect, useState} from "react";
import env from "../../../../../env";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Chip,
} from "@mui/material";

import { format } from 'date-fns';
import { th } from 'date-fns/locale'; 

import Divider from "@mui/material/Divider";
import GetIcon from "../utils/GetIcon";

import {GenerateAppKey} from "../../../../../utils/GenerateAppKey"

const ReportList: React.FC<any> = ({ reportData, sumStatus, setSelectedReportList }) => {
  const [imageData, setImageData] = useState<{ [key: string]: string }>({});

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy HH:mm:ss', { locale: th }); // ปรับรูปแบบตามต้องการ
  };

  function GetStatus(status: string) {
    switch (status) {
      case "wait":
        return "รอดำเนินการ";
      case "forward":
        return "ส่งต่อ";
      case "process":
        return "อยู่ระหว่างดำเนินการ";
      case "close":
        return "เสร็จสิ้น";
      default:
        return "";
    }
  }

  async function fetchImageAsDataURL(imagePath: string) {
    const { timestamp, appKey } = GenerateAppKey();
    const response = await fetch(`${env.API_URL}/image/${imagePath}`, {
      headers: {
        'Timestamp': timestamp.toString(),
        'App-Key': appKey,
      },
    });
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  }
  useEffect(() => {
    reportData.issues && 
    reportData.issues.forEach((report: any) => {
      fetchImageAsDataURL(report.file_url).then((dataUrl) => {
        setImageData((prev) => ({ ...prev, [report.file_url]: dataUrl }));
      });
    });
  }, [reportData]);
  
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ fontWeight: 300, marginTop: -2 }}>
          รายงานการแจ้งปัญหา
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ fontWeight: 100, marginTop: -2, fontSize: 14 }}
        >
          {sumStatus?.total} รายการ
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ fontWeight: 300, marginTop: -2 }}>
          <List
            sx={{
              width: "100%",
              maxWidth: 360,
              bgcolor: "background.paper",
              height: `32vh`,
              minHeight: 300,
              maxHeight: 1000,
              overflow: "auto",
            }}
          >
            {reportData.issues &&
              reportData.issues.map((report: any) => {
                let status = GetStatus(report.status);

                return (
                  <>
                    <ListItem onClick={()=>setSelectedReportList(report)} alignItems="flex-start">
                      <ListItemAvatar>
                        <Avatar
                          alt={report.description}
                          src={imageData[report.file_url] || ""}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={report.description}
                        secondary={
                          <React.Fragment>
                            <Typography
                              sx={{ display: "inline" }}
                              component="span"
                              variant="body2"
                              color="text.primary"
                            >
                              {report.category}
                            </Typography>
                            {report.needs_advice && ` — ${report.needs_advice}`}
                            {report.other_detail && ` - ${report.other_detail}`}
                            <Grid container>
                              <Grid item md={6} sx={{fontSize:12}}>
                                {formatDate(report.created_at)}
                              </Grid>
                              <Grid item md={6}>
                              <Chip
                              sx={{
                                float: "inline-end",
                                backgroundColor:
                                  report.status === "wait"
                                    ? "red"
                                    : report.status === "forward"
                                      ? "#4285f4"
                                      : report.status === "process"
                                        ? "#c78424"
                                        : report.status === "close"
                                          ? "#549c35"
                                          : "defaultColor",
                                color: "white",
                                fontSize: 10,
                              }}
                              icon={GetIcon(report.status)}
                              label={status}
                            />
                              </Grid>
                            </Grid>
                            
                          </React.Fragment>
                        }
                      />
                      
                    </ListItem>
                    <Divider component="li" />
                  </>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default ReportList;
