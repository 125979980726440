import { Grid } from "@mui/material";

const StatCard: React.FC<any> = ({
  xs,
  style,
  backgroundColor,
  stat,
  percentage,
  status,
}) => {
  return (
    <>
      <Grid
        container
        xs={xs}
        sx={{
          ...style,
          backgroundColor: backgroundColor,
          height: 50,
          borderRadius: 2,
          padding: 1,
        }}
      >
        <Grid
          item
          xs={12}
          sx={{ fontWeight: 300, color: "white", fontSize: 12 }}
        >
          {status}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ fontWeight: 300, color: "white", fontSize: 12 }}
        >
          {stat} ({percentage})
        </Grid>
      </Grid>
    </>
  );
};

export default StatCard;
