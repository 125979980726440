import { Grid } from "@mui/material";
import StatCard from "./StatCard";

const StatPanel: React.FC<any> = ({ data }) => {
  const getCountByStatus = (statusName: string) =>
    data?.countsByStatus.find((status: any) => status.status === statusName);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ fontWeight: 300, marginTop: 2 }}>
          สถิติการแจ้งปัญหา
        </Grid>
      </Grid>
      <Grid container sx={{ marginTop: 1, justifyContent: "space-between" }}>
        {/* รอรับเรื่อง */}
        <StatCard
          xs={5.8}
          status={"รอดำเนินการ"}
          backgroundColor={"red"}
          stat={getCountByStatus("wait")?.count ?? 0}
          percentage={`${(getCountByStatus("wait")?.percentage ?? 0).toFixed(2)}%`}
        />
        {/* ส่งต่อ */}
        <StatCard
          xs={5.8}
          backgroundColor={"#4285f4"}
          status="ส่งต่อ"
          stat={getCountByStatus("forward")?.count ?? 0}
          percentage={`${(getCountByStatus("forward")?.percentage ?? 0).toFixed(2)}%`}
        />
      </Grid>
      <Grid container sx={{ marginTop: 1, justifyContent: "space-between" }}>
        {/* อยู่ระหว่างดำเนินการ */}
        <StatCard
          xs={5.8}
          backgroundColor={"#c78424"}
          status="อยู่ระหว่างดำเนินการ"
          stat={getCountByStatus("process")?.count ?? 0}
          percentage={`${(getCountByStatus("process")?.percentage ?? 0).toFixed(2)}%`}
        />
        {/* เสร็จสิ้น */}
        <StatCard
          xs={5.8}
          backgroundColor={"#549c35"}
          status="เสร็จสิ้น"
          stat={getCountByStatus("close")?.count ?? 0}
          percentage={`${(getCountByStatus("close")?.percentage ?? 0).toFixed(2)}%`}
        />
      </Grid>
      <Grid container sx={{ marginTop: 1, justifyContent: "space-between" }}>
        {/* ทั้งหมด */}
        <StatCard
          xs={12}
          backgroundColor={"#595959"}
          status="ทั้งหมด"
          stat={data?.total}
          percentage={`เรื่อง`}
          style={{ textAlign: "center" }}
        />
      </Grid>
    </>
  );
};

export default StatPanel;
