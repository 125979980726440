import axios from "axios";
import env from "../env";
import {GenerateAppKey} from "../utils/GenerateAppKey"

const apiInstance = axios.create({
  baseURL: env.API_URL,
  headers: {
    'Cache-Control': 'no-cache, no-store, must-revalidate',  
    'Pragma': 'no-cache',  
    'Expires': '0' 
  }
});

export const fetchData = async (endpoint: string) => {
  try {
    const { timestamp, appKey } = GenerateAppKey();
    const noCacheTs = new Date().getTime();
    const response = await apiInstance.get(endpoint, {
      headers: {
        'timestamp': timestamp,
        'app-key': appKey,
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (endpoint: string, data: any) => {
  try {
    const { timestamp, appKey } = GenerateAppKey();
    const noCacheTs = new Date().getTime();
    const response = await apiInstance.post(endpoint, 
      data, {
      headers: {
        'Timestamp': timestamp,
        'App-Key': appKey,
        'Content-Type': 'application/json'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};
