import {
    Box,
    Card,
    Autocomplete,
    TextField,
    Chip
  } from "@mui/material";
  import AsyncStorage from '@react-native-async-storage/async-storage';

import { ProblemStatus } from "../../../../utils/ProblemStatus";
import { useEffect } from "react";
  
  const FilterStatus = ({ selectedStatus,setSelectedStatus }:any) => {
    const status = ProblemStatus

    useEffect(() => {
      const loadStatus = async () => {
        const storedStatus = await AsyncStorage.getItem('selectedStatus');
        if (storedStatus !== null) {
          setSelectedStatus(JSON.parse(storedStatus)); // Make sure to parse the stored string back into an object/array
        }
      };
  
      loadStatus();
    }, [setSelectedStatus]);

    const updateValue:any = async (value:any)=> {
      const jsonValue = JSON.stringify(value);
      await AsyncStorage.setItem('selectedStatus', jsonValue)
      setSelectedStatus(value)
    }
    
    return (
      <Card
      sx={{
        width: 350,
        position: "absolute",
        left: 350,
        margin: 1,
        marginTop: `${1}vh`,
        zIndex: 1,
        backgroundColor:"transparent",
        boxShadow:"none",
      }}
    >
      <Autocomplete
        value={selectedStatus || null}
        multiple
        limitTags={2}
        id="status-tags"
        options={status}
        onChange={(e,value)=>updateValue(value)}
        getOptionLabel={(status) => status.label}
        // defaultValue={selectedStatus || null}
        renderOption={(props, option) => (
          <li {...props} style={{ ...props.style, fontFamily: 'Mitr', color:option.color }}>
            {option.label}
          </li>
        )}
        renderTags={(tagValue, getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              style={{ backgroundColor: option.color, color:"white",fontFamily: 'Mitr' }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
          {...params}
          label="สถานะ"
          InputLabelProps={{
            style: { fontFamily: 'Mitr' },
          }}
          InputProps={{
            ...params.InputProps,
            style: { fontFamily: 'Mitr' },
          }}
          inputProps={{
            ...params.inputProps,
            style: { fontFamily: 'Mitr' }
          }}
        />
        )}
        sx={{ width:350, marginTop:1,marginBottom:1, backgroundColor:"white", borderRadius:2}}
      />
    </Card>
    );
  };
  
  export default FilterStatus;
  