import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { ToggleData, initialLayers } from "../components/layer/layers";



// Create the context
const MapContext = createContext<{
  layers: ToggleData[];
  toggleLayer: (name: string) => void;
}>({
  layers: initialLayers,
  toggleLayer: () => { }
});

export const useLayerContext = () => useContext(MapContext);

export const LayerProvider = ({ children }: { children: ReactNode }) => {
  const [layers, setLayers] = useState<ToggleData[]>(initialLayers);
  const [showedLayers, setShowedLayers] = useState<string[]>([]);

  const toggleLayer = (name: string) => {
    let refId = layers.findIndex(rec => rec?.layerConfig?.id == name)
    if (typeof (refId) !== 'number') return
    setLayers((prev: ToggleData[]) => {
      let copiedLayers = JSON.parse(JSON.stringify(prev))
      const oldState = !!copiedLayers[refId]?.layerConfig?.visible
      copiedLayers[refId].layerConfig = {
        ...copiedLayers[refId]?.layerConfig,
        visible: !oldState

      }
      return [...copiedLayers]
    })
  };



  return (
    <MapContext.Provider value={{ layers, toggleLayer }}>
      {children}
    </MapContext.Provider>
  );
};
