import React,{useEffect, useState} from "react";

import "mapbox-gl/dist/mapbox-gl.css";
import CustomPin from "./CustomPin";
import env from "../../../../env";

import Map, { Marker, Popup, useMap } from "react-map-gl";

import { useLayerContext } from "../../../../hooks/useMapBox";
import MapLayer from "../layer/MapLayer";
import { Card, CardContent, Chip, CircularProgress, Typography } from "@mui/material";
import GetIcon from "../left-panel/utils/GetIcon";
import {GenerateAppKey} from "../../../../utils/GenerateAppKey"
import { format } from 'date-fns';
import { th } from 'date-fns/locale'; 

const MapPanel: React.FC<any> = ({
  onMapLoad,
  mapPinData,
  selectedIssue,
  onUserClickPinmap,
  socketNewIssue,
  socketStatusChangeIssue,
  socketUpdateIssue,
  socketReopenIssue,
  selectedReportList,
  showUserPin,
  showOfficerPin,
  showFloodPin,
  showDroughtPin,
  selectedProvince
}) => {
  const [imageData, setImageData] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<any>(false)
  const { layers } = useLayerContext();
  const mapRef: any = useMap();


  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return format(date, 'dd MMMM yyyy HH:mm:ss', { locale: th });
  };

  function GetStatus(status: string) {
    switch (status) {
      case "wait":
        return "รอดำเนินการ";
      case "forward":
        return "ส่งต่อ";
      case "process":
        return "อยู่ระหว่างดำเนินการ";
      case "close":
        return "เสร็จสิ้น";
      default:
        return "";
    }
  }

  async function fetchImageAsDataURL(imagePath: string): Promise<string> {
    const { timestamp, appKey } = GenerateAppKey();
    try {
      const response = await fetch(`${env.API_URL}/image/${imagePath}`, {
        headers: {
          'Timestamp': timestamp.toString(),
          'App-Key': appKey,
        },
      });
  
      if (!response.ok) {
        throw new Error('Image not found');
      }
  
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error('Failed to fetch image:', error);
      return '/images/nophoto.jpg';
    }
  }

  useEffect(() => {
    if (selectedIssue) {
      setLoading(true);
      fetchImageAsDataURL(selectedIssue.file_url).then((dataUrl) => {
        setImageData((prev) => ({ ...prev, [selectedIssue.file_url]: dataUrl }));
        setLoading(false);
      });
    }
  }, [selectedIssue]);

  useEffect(() => {
    if (mapRef.current && selectedReportList) {
      const map = mapRef.current.getMap(); 
  
      map.flyTo({
        center: [selectedReportList.longitude, selectedReportList.latitude],
        zoom: 14, 
        offset: [0, -250],
        essential: true
      });
      onUserClickPinmap(selectedReportList.issue_id)
    }
  },[selectedReportList])

  useEffect(()=>{
    if (mapRef.current && selectedProvince) {
      console.log('mapfly------------',selectedProvince)
      const map = mapRef.current.getMap(); 
  
      map.flyTo({
        center: [selectedProvince.lng, selectedProvince.lat],
        zoom: 10, 
        essential: true
      });
    }
  },[mapRef.current,selectedProvince])

  return (
    <>
      <Map
        mapboxAccessToken={env.MAPBOX_TOKEN}
        initialViewState={{
          longitude: 100.537487,
          latitude: 13.785867,
          zoom: 5.2,
        }}
        mapStyle={env.MAPBOX_STYLE}
        style={
          {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            sources: {
              "raster-tiles": {
                type: "raster",
                tiles: ["https://tile.openstreetmap.org/{z}/{x}/{y}.png"],
                tileSize: 256,
                attribution:
                  '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
              },
            },
          } as any
        }
        ref={mapRef}
        onLoad={onMapLoad}
      >
        {layers.map((layer) => (
          <MapLayer layer={layer} />
        ))}

        {mapPinData &&
          mapPinData.map((issue: any) => (
            <Marker
              key={issue.issue_id}
              longitude={Number(issue.longitude)}
              latitude={Number(issue.latitude)}
              anchor="bottom"
              onClick={(e) => {
                e.originalEvent.stopPropagation();
                onUserClickPinmap(issue.issue_id);
              }}
            >
              {((issue.user_sources === 'line' && showUserPin) ||
                (issue.user_sources === 'api' && showOfficerPin)) && 
              ((issue.type === 'flood' && showFloodPin) || 
                (issue.type === 'drought' && showDroughtPin)) ? (
                  <CustomPin
                    onClick={() => onUserClickPinmap(issue.issue_id)}
                    source={issue.user_sources}
                    category={issue.category}
                    status={issue.status}
                    isNewIssue={issue.issue_id === socketNewIssue}
                    isStatusChangeIssue={issue.issue_id === socketStatusChangeIssue}
                    isUpdateIssue={issue.issue_id === socketUpdateIssue}
                    isReopenIssue={issue.issue_id === socketReopenIssue}
                  />
                ) : null}
            </Marker>
          ))}

        {selectedIssue && (
          <>
            <Popup
              key={selectedIssue.issue_id}
              longitude={Number(selectedIssue.longitude)}
              latitude={Number(selectedIssue.latitude)}
              onClose={() => onUserClickPinmap(null)}
            >
              <>
              <div 
              >

            { loading ?
              <CircularProgress />
            :
            selectedIssue?.file_url?.endsWith('.mp4') ? (
              <video style={{height: 220,width: 220, objectFit:'cover', borderRadius: 10 }} controls>
                <source src={imageData[selectedIssue.file_url]} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <img
                style={{ height: 220,width: 220, objectFit:'cover', borderRadius: 10 }}
                src={imageData[selectedIssue.file_url]}
                alt="Issue content"
              />
            )
            }

            </div>
                <Card sx={{ width: 220, borderRadius: 4 }}>
                  <CardContent sx={{maxHeight:400,overflow:"auto"}}>
                    <Typography gutterBottom component="div" style={{fontSize:'1rem'}}>
                      {selectedIssue.description}
                    </Typography>
                    <Typography variant="body2" color="black">
                      {selectedIssue.category}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span style={{color:'black'}}>ความต้องการ/คำแนะนำ:</span> {selectedIssue.needs_advice}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span style={{color:'black'}}>รายละเอียดอื่นๆ:</span> {selectedIssue.other_detail}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span style={{color:'black'}}>พิกัด:</span> {selectedIssue.province_th} {selectedIssue.amphoe_th} {selectedIssue.tambon_th} ({selectedIssue.longitude},{selectedIssue.latitude})
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <span style={{color:'black'}}>Case:</span> {selectedIssue.issue_id}
                    </Typography>
                    
                    <Chip
                      sx={{
                        float: "inline-end",
                        backgroundColor:
                          selectedIssue.status === "wait"
                            ? "red"
                            : selectedIssue.status === "forward"
                              ? "#4285f4"
                              : selectedIssue.status === "process"
                                ? "#c78424"
                                : selectedIssue.status === "close"
                                  ? "#549c35"
                                  : "defaultColor",
                        color: "white",
                        fontSize: 10,
                        marginTop: 2,
                        marginBottom: 2,
                      }}
                      icon={GetIcon(GetStatus(selectedIssue.status))}
                      label={GetStatus(selectedIssue.status)}
                    />
                     {formatDate(selectedIssue.created_at)}
                  </CardContent>
                </Card>
              </>
            </Popup>
          </>
        )}
      </Map>
    </>
  );
};

export default MapPanel;
