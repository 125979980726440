import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LayerProvider } from "./hooks/useMapBox";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <LayerProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </LayerProvider>
  </BrowserRouter>
);
