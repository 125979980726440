import { Grid } from "@mui/material";
import { BarChart } from "@mui/x-charts/BarChart";

const chartSetting = {
  width: 350,
  height: 200,
};

const ChartPanel: React.FC<any> = ({ data }) => {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ fontWeight: 300, marginTop: 2 }}>
        ประเภทปัญหาที่ขอความช่วยเหลือ
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ marginTop: -5 }}>
          <BarChart
            sx={{
              "& .MuiChartsAxis-tickContainer .MuiChartsAxis-tickLabel": {
                fontFamily: "Mitr",
                fontSize: "6px !important",
                fontWeight: "100",
              },
            }}
            dataset={data}
            yAxis={[
              {
                scaleType: "band",
                dataKey: "category",
              },
            ]}
            series={[{ dataKey: "value" }]}
            layout="horizontal"
            {...chartSetting}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChartPanel;
