import {
  HourglassTop as HourglassTopIcon,
  Forward as ForwardIcon,
  DoneAll as DoneAllIcon,
  Engineering as EngineeringIcon,
} from "@mui/icons-material";

const getIcon = (status: any) => {
  switch (status) {
    case "wait":
      return <HourglassTopIcon style={{ color: "white", fontSize: 14 }} />;
    case "forward":
      return <ForwardIcon style={{ color: "white", fontSize: 14 }} />;
    case "process":
      return <EngineeringIcon style={{ color: "white", fontSize: 14 }} />;
    case "close":
      return <DoneAllIcon style={{ color: "white", fontSize: 14 }} />;
    default:
      return undefined;
  }
};

export default getIcon;
