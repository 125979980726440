import {
    Box,
    Card,
    FormControlLabel,
    Stack,
  } from "@mui/material";
  import AntSwitch from "../AntSwitch";
  const FilterOfficerPin = ({ show, onChange }:any) => {

    return (
        <Card
        sx={{
          width: "145px",
          position: "absolute",
          right: 0,
          margin: 1,
          marginTop: `${8}vh`,
          zIndex: 1,
          borderRadius: 3,
          fontWeight: 300,
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          component="span"
          alignItems={"center"}
          sx={{ p: 1 }}
        >
          <>{'👩🏻‍💻เจ้าหน้าที่'}</>
          <FormControlLabel
            control={
              <AntSwitch onChange={(e) => onChange(e.target.checked)} color="primary" checked={show} />
            }
            label={""}
            labelPlacement="start"
            sx={{ justifyContent: "space-between", p: 1 }}
          />
        </Stack>
      </Card>
    );
  };
  
  export default FilterOfficerPin;
  