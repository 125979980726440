import { Layer, Source } from "react-map-gl";
import { ToggleData } from "../../../../components/layer/layers";
export default function MapLayer({ layer }: { layer: ToggleData }) {
  return (
    layer.sourceConfig &&
    layer?.layerConfig && (
      <>
        <Source {...layer.sourceConfig}>
          {layer?.layerConfig?.visible && <Layer {...layer.layerConfig} />}
        </Source>
      </>
    )
  );
}
