import "./HomePage.css";
import { fetchData } from "../../api/api";

import React, { useEffect, useState, useCallback } from "react";

import io from "socket.io-client";

import Map, { useMap } from "react-map-gl";

import MapSidebar from "./components/layer/FilterSidebar";
import MapPanel from "./components/map/MapPanel";
import LeftPanel from "./components/left-panel/LeftPanel";
import env from "../../env";
import FilterUserPin from "./components/filter/FilterUserPin";
import FilterOfficerPin from "./components/filter/FilterOfficerPin";
import { ProblemStatus } from "../../utils/ProblemStatus";
import FilterDroughtPin from "./components/filter/FilterDroughtPin";
import FilterFloodPin from "./components/filter/FilterFloodPin";
import NotificationsIcon from '@mui/icons-material/Notifications';
import Badge from '@mui/material/Badge';
import { Modal, List, ListItem, ListItemText, ListItemSecondaryAction, IconButton } from '@mui/material';
import InfiniteScroll from 'react-infinite-scroll-component';

const socket_line = io(env.LINE_URL);
const socket_api = io(env.API_URL);

function App() {
  const mapRef: any = useMap();
  
  const [mapPinData, setMapPinData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [selectedIssue, setSelectedIssue] = useState<any>();

  const [socketNewIssue, setSocketNewIssue] = useState<any>();
  const [socketStatusChangeIssue, setSocketStatusChangeIssue] = useState<any>();
  const [socketUpdateIssue, setSocketUpdateIssue] = useState<any>();
  const [socketReopenIssue, setSocketReopenIssue] = useState<any>();

  const [sumStatus, setSumStatus] = useState<any>();
  const [selectedReportList, setSelectedReportList] = useState<any>();
  const [broadcastMessage, setBroadcastMessage] = useState<any>(null);
  const [imageBroadcastMessage, setImageBroadcastMessage] = useState<any>(null);
  const [videoBroadcastMessage, setVideoBroadcastMessage] = useState<any>(null);
  const [showMarquee, setShowMarquee] = useState<boolean>(false);
  const [showImageMarquee, setShowImageMarquee] = useState<boolean>(false);
  const [showVideoMarquee, setShowVideoMarquee] = useState<boolean>(false);
  const [showUserPin, setShowUserPin] = useState<boolean>(true);
  const [showOfficerPin, setShowOfficerPin] = useState<boolean>(true);
  const [showDroughtPin, setShowDroughtPin] = useState<boolean>(true);
  const [showFloodPin, setShowFloodPin] = useState<boolean>(true);
  const [selectedProvince, setSelectedProvince] = useState<any>();
  const [selectedStatus, setSelectedStatus] = useState<any>(ProblemStatus);
  const [currentPage, setCurrentPage] = useState('1');
  
  const [isImageMarqueeCollapsed, setIsImageMarqueeCollapsed] = useState(false);
  const [isVideoMarqueeCollapsed, setIsVideoMarqueeCollapsed] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [broadcastHistory, setBroadcastHistory] = useState<any>([]);
  const [hasMore, setHasMore] = useState(true);

  const [selectedVideo, setSelectedVideo] = useState<string | null>(null);
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const handleImageClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
    setSelectedImage(null);
  };


  const handleVideoClick = (videoUrl: string) => {
    setSelectedVideo(videoUrl);
    setIsVideoModalOpen(true);
  };

  const closeVideoModal = () => {
    setIsVideoModalOpen(false);
    setSelectedVideo(null);
  };

  const [categoryChartData, setCategoryChartData] = useState([
    {
      value: 0,
      category: "น้ำใช้อุปโภค",
    },
    {
      value: 0,
      category: "น้ำดื่มน้ำบริโภค",
    },
    {
      value: 0,
      category: "น้ำเพื่อการเกษตร",
    },
    {
      value: 0,
      category: "น้ำป่าไหลหลาก",
    },
    {
      value: 0,
      category: "น้ำท่วม/น้ำขัง",
    },
    {
      value: 0,
      category: "น้ำล้นตลิ่ง",
    },
  ]);

  const toggleImageMarquee = () => {
    setIsImageMarqueeCollapsed(!isImageMarqueeCollapsed);
  };

  const toggleVideoMarquee = () => {
    setIsVideoMarqueeCollapsed(!isVideoMarqueeCollapsed);
  };

  const onUserClickPinmap = (issue_id:any) => {
    if(issue_id){
      fetchData(`/?columns=issue_id,user_id,latitude,longitude,file_url,category,status,created_at,updated_at,description,other_detail,needs_advice,province_th,amphoe_th,tambon_th&issue_id=${issue_id}`)
      .then((apiData) => setSelectedIssue(apiData[0]))
      .catch((error) => console.error("Error fetching data:", error));
    }
    setSelectedIssue(null)
  }

  const updateChartData = (apiData: any) => {
    const updatedData = categoryChartData.map((item) => {
      const matchingCategory = apiData.countsByCategory.find(
        (apiItem: any) => apiItem.category === item.category
      );
      const newCount = matchingCategory ? Number(matchingCategory.count) : 0;
      return {
        ...item,
        value: newCount,
      };
    });
    setCategoryChartData(updatedData);
  };

  const onMapLoad = useCallback(() => {}, []);

  const getMapPinData = () => {
    const statusString = selectedStatus.map((status:any) => status.value).join(',');
    let query = `/?columns=issue_id,latitude,longitude,user_sources,category,type,status&status=${statusString}`
    if(selectedProvince?.province_code){
      query += `&province=${selectedProvince?.province_code}`
    }
    fetchData(`${query}`)
    .then((apiData) => setMapPinData(apiData))
    .catch((error) => console.error("Error fetching data:", error));
  }

  const getReportProblemList = (page='1') => {
    setCurrentPage(page)
    const statusString = selectedStatus.map((status:any) => status.value).join(',');
    const userSourceString = `line_filter=${showUserPin}&api_filter=${showOfficerPin}`
    const typeString = `drought_filter=${showDroughtPin}&flood_filter=${showFloodPin}`
    let query = `/?page=${page}&limit=10&status=${statusString}&${userSourceString}&${typeString}&columns=issue_id,latitude,longitude,file_url,category,status,created_at,updated_at,description,needs_advice,other_detail,province_th,amphoe_th,tambon_th`
    if(selectedProvince?.province_code){
      query += `&province=${selectedProvince?.province_code}`
    }
    fetchData(query)
      .then((apiData) => setReportData(apiData))
      .catch((error) => console.error("Error fetching data:", error));
  };

  const getSumCategory = (province=null) => {
    const statusString = selectedStatus.map((status:any) => status.value).join(',');
    let query = `/sum-category?status=${statusString}&drought_filter=${showDroughtPin}&flood_filter=${showFloodPin}&line_filter=${showUserPin}&api_filter=${showOfficerPin}`
    if(selectedProvince?.province_code){
      query += `&province=${selectedProvince?.province_code}`
    }
    fetchData(query)
      .then((apiData) => updateChartData(apiData))
      .catch((error) => console.error("Error fetching data:", error));
  };
  

  const getSumStatus = () => {
    const statusString = selectedStatus.map((status:any) => status.value).join(',');
    let query = `/sum-status?status=${statusString}&drought_filter=${showDroughtPin}&flood_filter=${showFloodPin}&line_filter=${showUserPin}&api_filter=${showOfficerPin}`
    if(selectedProvince?.province_code){
      query += `&province=${selectedProvince?.province_code}`
    }
    fetchData(query)
      .then((data) => {
        const total = parseInt(data.total, 10);
        const countsByStatus = data.countsByStatus.map((statusCount: any) => ({
          ...statusCount,
          count: parseInt(statusCount.count, 10),
          percentage: (parseInt(statusCount.count, 10) / total) * 100,
        }));

        setSumStatus({ total, countsByStatus });
      })
      .catch((error) =>
        console.error("Error fetching sum status data:", error)
      );
  };

  const fetchBroadcastHistory = (offset=0) => {
    fetchData(`/broadcast?limit=10&offset=${offset}`)
      .then((apiData) => {
        if (apiData.length < 10) {
          setHasMore(false);
        }
        setBroadcastHistory((prevHistory:any) => [...prevHistory, ...apiData]);
      })
      .catch((error) => console.error("Error fetching broadcast history:", error));
  }

  useEffect(()=> {
    getMapPinData()
    getReportProblemList(currentPage)
    getSumCategory();
    getSumStatus();
  },[selectedStatus,selectedProvince,showDroughtPin,showFloodPin,showOfficerPin,showUserPin])

  useEffect(() => {
    getMapPinData();
    getReportProblemList(currentPage);
    getSumCategory();
    getSumStatus();
  }, []);

  useEffect(() => {
    socket_line.on("newIssue", (data) => {
      setSocketNewIssue(data.issue_id);
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });
    socket_api.on("newIssue", (data) => {
      setSocketNewIssue(data.issue_id);
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });

    return () => {
      socket_line.off("newIssue");
      socket_api.off("newIssue");
    };

  }, []);

  useEffect(() => {
    socket_api.on("statusChangedIssue", (data) => {
      setSocketStatusChangeIssue(data.issue_id);
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });

    return () => {
      socket_api.off("statusChangedIssue");
    };
  }, []);

  useEffect(() => {
    socket_api.on("updateIssue", (data) => {
      setSocketUpdateIssue(data.issue_id);
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });

    return () => {
      socket_api.off("updateIssue");
    };
  }, []);

  useEffect(() => {
    socket_api.on("deactivateIssue", () => {
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });

    return () => {
      socket_api.off("deactivateIssue");
    };
  }, []);

  useEffect(() => {
    socket_api.on("reopenIssue", (data) => {
      setSocketReopenIssue(data.issue_id);
      getMapPinData();
      getReportProblemList(currentPage);
      getSumCategory();
      getSumStatus();
    });

    return () => {
      socket_api.off("reopenIssue");
    };
  }, []);

  useEffect(() => {
    socket_api.on("broadcast", (data: any) => {
      console.log("broadcast",data)
      setNotificationCount((prevCount) => prevCount + 1); 
      if(data.type === "text"){
        setShowMarquee(true);
        setBroadcastMessage(`${data.message}`);
      }
      if(data.type === "image"){
        setImageBroadcastMessage(`${data.message}`);
        setShowImageMarquee(true);
        setTimeout(() => setShowImageMarquee(false), 60000); 
      }
      if(data.type === "video"){
        setVideoBroadcastMessage(`${data.message}`);
        setShowVideoMarquee(true);
        setTimeout(() => setShowVideoMarquee(false), 60000);
      }
    });
  
    return () => {
      socket_api.off("broadcast");
    };
  }, []);

  useEffect(() => {
    const handleMarqueeEnd = () => {
      setShowMarquee(false);
    };
  
    const marqueeElement = document.getElementById("marqueeText");
    marqueeElement?.addEventListener("animationend", handleMarqueeEnd);
  
    return () => {
      marqueeElement?.removeEventListener("animationend", handleMarqueeEnd);
    };
  }, []);

  const handleNotificationClick = () => {
    setIsModalOpen(true);
    setNotificationCount(0); 
    setBroadcastHistory([])
    fetchBroadcastHistory()
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fetchMoreBroadcastHistory = () => {
    fetchBroadcastHistory(broadcastHistory.length);
  };

  return (
    <div style={{ height: "100vh", width: "100vw", overflow: "hidden" }}>
      <div className="marquee" style={{ display: showMarquee ? "block" : "none" }}>
        <span id="marqueeText">{broadcastMessage}</span>
      </div>

      <div className="imageMediaMarquee" style={{ display: showImageMarquee ? "block" : "none", width: isImageMarqueeCollapsed ? '5vw' : '14vw', height: isImageMarqueeCollapsed ? '50px' : '30vh' }}>
        <div className="mediaOverlay">Broadcast 📢</div>
        <button className="collapseButton" onClick={toggleImageMarquee}>{isImageMarqueeCollapsed ? 'ขยาย' : 'ย่อ'}</button>
        <img src={imageBroadcastMessage} className="mediaContent"></img>
      </div>

      <div className="videoMediaMarquee" style={{ display: showVideoMarquee ? "block" : "none", width: isVideoMarqueeCollapsed ? '5vw' : '14vw', height: isVideoMarqueeCollapsed ? '50px' : '30vh' }}>
        <div className="mediaOverlay">Broadcast 📢</div>
        <button style={{zIndex:99999}} className="collapseButton" onClick={toggleVideoMarquee}>{isVideoMarqueeCollapsed ? 'ขยาย' : 'ย่อ'}</button>
        {videoBroadcastMessage && 
          <video className="mediaContent" controls>
            <source src={videoBroadcastMessage} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        }
      </div>

      <div className="notification-container">
        <IconButton style={{backgroundColor:"#4285f4", color:"yellow"}} onClick={handleNotificationClick}>
          <Badge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
      </div>

      <Modal open={isModalOpen} onClose={closeModal}>
        <div className="modal-content">
          <InfiniteScroll
            dataLength={broadcastHistory.length}
            next={fetchMoreBroadcastHistory}
            hasMore={hasMore}
            loader={<h4>Loading...</h4>}
          >
            <List>
              {broadcastHistory.map((item: any, index: any) => (
                <ListItem key={index}>
                  <ListItemText
                    primary={item.message}
                    secondary={new Date(item.created_at).toLocaleString()}
                  />
                  {item.type === "image" && (
                    <img
                      src={item.message}
                      alt="broadcast"
                      style={{ width: "50px", height: "50px", cursor: 'pointer' }}
                      onClick={() => handleImageClick(item.message)}
                    />
                  )}
                  {item.type === "video" && (
                    <video
                      width="50"
                      height="50"
                      onClick={() => handleVideoClick(item.message)}
                      style={{ cursor: 'pointer' }}
                    >
                      <source src={item.message} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  )}
                </ListItem>
              ))}
            </List>
            {broadcastHistory.length === 0 && <p>No broadcast</p>}
          </InfiniteScroll>
        </div>
      </Modal>


      <Modal open={isImageModalOpen} onClose={closeImageModal}>
        <div className="image-modal-content">
          {selectedImage && (
            <img src={selectedImage} alt="broadcast" style={{ width: "100%", height: "100%" }} />
          )}
        </div>
      </Modal>

      <Modal open={isVideoModalOpen} onClose={closeVideoModal}>
        <div className="video-modal-content">
          {selectedVideo && (
            <video width="100%" height="100%" controls>
              <source src={selectedVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      </Modal>




      <LeftPanel
        sumStatus={sumStatus}
        categoryChartData={categoryChartData}
        reportData={reportData}
        setSelectedReportList={setSelectedReportList}
        setSelectedProvince={setSelectedProvince}
        setSelectedStatus={setSelectedStatus}
        getReportProblemList={getReportProblemList}
        selectedProvince={selectedProvince}
        selectedStatus={selectedStatus}
      />

      {/* Right */}
      <FilterDroughtPin show={showDroughtPin} onChange={setShowDroughtPin}/>
      <FilterFloodPin show={showFloodPin} onChange={setShowFloodPin}/>
      <FilterUserPin show={showUserPin} onChange={setShowUserPin}/>
      <FilterOfficerPin show={showOfficerPin} onChange={setShowOfficerPin} />

      <MapSidebar map={mapRef} />

      {/* Map */}

      <MapPanel
        onMapLoad={onMapLoad}
        mapPinData={mapPinData}
        reportData={reportData}
        selectedIssue={selectedIssue}
        onUserClickPinmap={onUserClickPinmap}
        socketNewIssue={socketNewIssue}
        socketStatusChangeIssue={socketStatusChangeIssue}
        socketUpdateIssue={socketUpdateIssue}
        socketReopenIssue={socketReopenIssue}
        selectedReportList={selectedReportList}
        showUserPin={showUserPin}
        showOfficerPin={showOfficerPin}
        showFloodPin={showFloodPin}
        showDroughtPin={showDroughtPin}
        selectedProvince={selectedProvince}
      />
    </div>
  );
}

export default App;
