import "mapbox-gl/dist/mapbox-gl.css";
import { Box, Grid, Pagination } from "@mui/material";
import StatPanel from "./stats/StatPanel";
import ChartPanel from "./chart-category/ChartPanel";
import ReportList from "./report/ReportList";
import FilterProvince from "../filter/FilterProvince";
import FilterStatus from "../filter/FilterStatus";

const LeftPanel: React.FC<any> = ({
  sumStatus,
  categoryChartData,
  reportData,
  setSelectedReportList,
  setSelectedProvince,
  setSelectedStatus,
  getReportProblemList,
  selectedProvince,
  selectedStatus
}) => {
  return (
    <>
      <FilterProvince selectedProvince={selectedProvince} setSelectedProvince={setSelectedProvince} />
      <FilterStatus selectedStatus={selectedStatus} setSelectedStatus={setSelectedStatus} />
    
      <Box
        sx={{
          margin: 1,
          marginTop: 10,
          width: 300,
          height: "85%",
          maxHeight: 1000,
          backgroundColor: "white",
          position: "absolute",
          borderRadius: 5,
          left: 0,
          zIndex: 1,
          padding: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ textAlign: "center", fontSize: 18 }}>
          ศูนย์รับเรื่องปัญหาน้ำ
          </Grid>
        </Grid>

        <StatPanel data={sumStatus} />

        <ChartPanel data={categoryChartData} />

        <ReportList sumStatus={sumStatus} reportData={reportData} setSelectedReportList={setSelectedReportList} />
        <Pagination sx={{justifyContent:"center",display:"flex"}} onChange={(e,page)=>getReportProblemList(page)} siblingCount={0} count={reportData.totalPages}/>
      </Box>
    </>
  );
};

export default LeftPanel;
