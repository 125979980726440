import env from "../../env";
export interface ToggleData {
  label: string;
  sourceConfig?: any;
  layerConfig?: any;
}
//http://10.10.10.35:8080/geoserver/drought/wms?service=WMS&version=1.1.0&request=GetMap&layers=drought%3Adri&bbox=97.34396362304688%2C5.617401599884033%2C105.64291381835938%2C20.4700984954834&width=429&height=768&srs=EPSG%3A4326&styles=&format=image%2Fpng
// Define the initial layers data
export const initialLayers: ToggleData[] = [
  {
    label: "ดัชนีความเสียงภัยแล้ง (DRI)",
    sourceConfig: {
      id: "driSource",
      type: "raster",
      tiles: [
        `${env.GEOSERVER_URL}/drought/wms?service=WMS&version=1.1.0&request=GetMap&layers=drought%3Adri&bbox={bbox-epsg-3857}&srs=EPSG:3857&format=image%2Fpng&WIDTH=256&height=256&TRANSPARENT=true`,
      ],
      tileSize: 256,
    },
    layerConfig: {
      id: "driLayer",
      type: "raster",
      minzoom: 0,
      maxzoom: 22,
      visible: false,
    },
  },
  {
    label: "พื้นที่ภัยแล้งซ้ำซาก",
    sourceConfig: {
      id: "weatherSource",
      type: "raster",
      tiles: [
        "https://geoportal.rtsd.mi.th/arcgis/rest/services/Vector/พื้นที่ภัยแล้ง/MapServer/export?bbox={bbox-epsg-3857}&bboxSR=3857&imageSR=3857&size=512%2C512&dpi=96&format=png32&transparent=true&layers=show%3A0&f=image",
      ],
      tileSize: 256,
    },
    layerConfig: {
      id: "weatherLayer",
      type: "raster",
      minzoom: 0,
      maxzoom: 22,
      visible: false,
    },
  },

  {
    label: "พื้นที่ชลประทาน",
    sourceConfig: {
      id: "irriSource",
      type: "raster",
      tiles: [
        `${env.GEOSERVER_URL}/drought/wms?service=WMS&version=1.1.0&request=GetMap&layers=drought%3Airrigation_area&bbox={bbox-epsg-3857}&srs=EPSG:3857&format=image%2Fpng&WIDTH=256&height=256&TRANSPARENT=true`,
      ],
      tileSize: 256,
    },
    layerConfig: {
      id: "irriLayer",
      type: "raster",
      minzoom: 0,
      maxzoom: 22,
      visible: false,
    },
  },
  // { label: "Toggle ระดับน้ำ" },
  // { label: "Toggle บ่อน้ำบาดาล" },
  // { label: "Toggle CCTV" }
];
