import {
    Card,
    FormControlLabel,
    Stack,
  } from "@mui/material";
  import AntSwitch from "../AntSwitch";
  
  const FilterDroughtPin = ({ show, onChange }:any) => {

    return (
      <>
        <Card
        sx={{
          width: "150px",
          position: "absolute",
          right: 0,
          margin: 1,
          marginTop: `${1}vh`,
          zIndex: 1,
          borderRadius: 3,
          fontWeight: 300,
          marginRight: "158px",
        }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          component="span"
          alignItems={"center"}
          sx={{ p: 1 }}
        >
          <>{'☀️ภัยแล้ง'}</>
          <FormControlLabel
            control={
              <AntSwitch onChange={(e) => onChange(e.target.checked)} color="primary" checked={show} />
            }
            label={""}
            labelPlacement="start"
            sx={{ justifyContent: "space-between", p: 1 }}
          />
        </Stack>
      </Card>
      </>
    );
  };
  
  export default FilterDroughtPin;
  